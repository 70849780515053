.footer {
    width: 100%;
    background: #B31B1B;
    height: 68px;
    padding-left: 81px;
    padding-top: 23px;
    padding-bottom: 23px;
}

.footer p {
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media all and (max-width:767px) {
    .footer p {
        font-size: 10px;
    }
}