.our-clients {
    padding-left: 226px;
    /* padding-right: 106px; */
    padding-bottom: 106px;
}

.our-clients h2 {
    color: #B31B1B;
    font-family: 'Roboto', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 80px;
}

.our-clients .h2-en {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin-right: 0px;
}

.our-clients .h2-ar {
    font-family: 'Cairo', sans-serif;
    text-align: right;
    margin-right: 103px;
}

.our-clients img {
    width: 50%;
    margin-right: 46px;
    margin-bottom: 59px;
}

@media all and (max-width:767px) {
    .our-clients {
        padding-left: 35px;
        padding-bottom: 35px;
    }

    .our-clients h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .our-clients {
        padding-left: 55px;
        padding-bottom: 55px;
    }
}