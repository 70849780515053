@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

:root {
    --swiper-pagination-bullet-inactive-color: white
}

.App {
    overflow: hidden;
}

.flex-aswad-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none;
}