.department {
    padding-top: 336px;
    padding-left: 226px;
}

.images {
    width: 100%;
    position: relative;
}

.archi {
    width: 100%;
}

.dots {
    position: absolute;
    right: 77px;
    top: -69px;
    width: 127px;
    height: 496px;
    z-index: -1;
}

.department .text {
    padding-left: 85px;
}

.department .text-ar h2 {
    margin-right: 54px;
}

.department .text-ar h2,
.department .text-ar p {
    font-family: 'Cairo', sans-serif;
    text-align: right;
}

.department h2 {
    color: #B31B1B;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 28px;

}

.department p {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 85%;
    margin-bottom: 8px;
}

.flex-aswad-end {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 55px;
}

.flex-aswad-end h6 {
    color: #B31B1B;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    margin-right: 12px;
}

.flex-aswad-end h6:hover {
    color: #666;
    transition: .3s;
}

.text-2 {
    padding-left: 0px !important;
}

.images-2 {
    padding-right: 85px !important;
}

.dots-2 {
    top: -85px !important;
    right: 136px;

}

.last-department {
    padding-bottom: 226px;
}

@media all and (max-width:767px) {
    .dots {
        width: 207px;
        height: 70px;
        right: -68px;
        transform: rotate(90deg);
        top: 49px !important;
    }

    .department p {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .flex-aswad-end {

        padding-right: 0px;
    }

    .flex-aswad-end h6 {
        font-size: 10px;
        margin: 0;
    }

    .last-department {
        padding-bottom: 133px;
    }

    .flex-aswad-end svg {
        width: 19px;
        height: 15px;
        margin-left: 12px;
    }
}

@media all and (width:1024px) {
    .dots {
        width: 700px;
        height: 108px;
        right: -294px;
        transform: rotate(90deg);
        top: 222px !important;
    }


}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .dots-2 {
        top: -53px !important;
        right: 2px;
    }

    .dots {
        width: 537px;
        height: 100px;
        right: -215px;
        transform: rotate(90deg);
        top: 173px !important;
    }
}