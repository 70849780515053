.contact-us {
    padding-left: 226px;
    padding-bottom: 106px;
    padding-top: 336px;
}

.contact-us .text:first-of-type {
    margin-bottom: 57px;
}

.contact-us h2 {
    color: #B31B1B;
    font-family: 'Roboto', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 20px;
}

.contact-us .text-ar h2,
.contact-us .text-ar p,
.contact-us .h2-form-ar,
.contact-us .h2-ar,
.contact-us .input-ar {
    font-family: 'Cairo', sans-serif;
}



.contact-us p {
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    line-height: 50px;
}

.contact-us .img img {
    padding-right: 50px;
}

.contact-us img {
    width: 100%;
}

.second-row {
    padding-top: 226px;
}

.second-row textarea {
    height: 100%;
    width: 70%;
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 16px;
    padding-left: 16px;
}

.second-row input {
    border: 2px solid #D9D9D9;
    width: 60%;
    padding: 16px;
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 36px;
}

.second-row input:focus {
    outline: none;
    border: 2px solid #d9d9d9b8;

}

.second-row a {
    display: inline-block;
    width: 20%;
    color: #FFF !important;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    border: 2px solid #666;
    background: #666;
    padding: 16px;
}

@media all and (width:1024px) {
    .contact-us {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 302px;
    }

    .flex-aswad-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .contact-us .img img {
        padding-right: 0px;
    }

    .contact-us h2 {
        font-size: 26px;
        margin-bottom: 8px;
    }

    .contact-us p {
        font-size: 26px;
        line-height: 40px;

    }

    .contact-us .text:first-of-type {
        margin-right: 134px;
        margin-bottom: 29px;
    }

    .second-row {
        padding-top: 77px;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-us .h2-form {
        text-align: center;
        margin-bottom: 25px;
    }

    .flex-sm-center a {
        width: 101%;
    }

    .second-row textarea {
        padding: 8px 16px;
        border: 2px solid #D9D9D9;
        width: 60%;
        margin-bottom: 25px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .contact-us {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 152px;
    }

    .contact-us .img img {
        padding-right: 0px;
    }

    .flex-aswad-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .contact-us h2 {
        font-size: 26px;
        margin-bottom: 8px;
    }

    .contact-us p {
        font-size: 26px;
        line-height: 40px;

    }

    .contact-us .text:first-of-type {
        margin-right: 134px;
        margin-bottom: 29px;
    }

    .second-row {
        padding-top: 77px;
    }

    .second-row textarea {
        padding: 8px 16px;
        border: 2px solid #D9D9D9;
        width: 60%;
        margin-bottom: 25px;
    }
}

@media all and (max-width:767px) {
    .contact-us {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 152px;
    }

    .flex-aswad-row {
        display: flex;
        flex-direction: row;
    }

    .contact-us h2 {
        font-size: 16px;
        margin-bottom: 3px;
    }

    .contact-us p {
        font-size: 8px;
        line-height: 10px;

    }

    .contact-us .text:first-of-type {
        margin-right: 134px;
        margin-bottom: 29px;
    }

    .contact-us .img img {
        padding-right: 0px;
    }

    .second-row {
        padding-top: 32px;
    }

    .second-row input,
    .second-row textarea {
        width: 100%;
        padding: 8px 16px;
        font-size: 16px;
        margin-bottom: 16px;
        border: 2px solid #D9D9D9;
        margin-bottom: 22px;
    }

    .second-row .h2-form {
        color: #B31B1B;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 22px;
    }

    .second-row a {
        width: 60%;
        padding: 8px 16px;
        font-size: 18px;
    }

    .flex-sm-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}