.hero {
    height: 100vh;
}

.hero h2 {
    color: #B31B1B;
    text-shadow: 0px 4px 25px rgba(0, 0, 0, 0.50);
    font-family: 'Roboto', sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 300px;
}

.hero .hero-ar {
    font-family: 'Cairo', sans-serif;
    margin-right: 0px;
}


.hero h4 {
    color: white;
    text-shadow: 0px 4px 25px rgba(0, 0, 0, 0.50);
    font-family: 'Roboto', sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 44px;
}

.img-slide-one,
.img-slide-two,
.img-slide-three,
.img-slide-four {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-family: 'Roboto', sans-serif;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

}



.img-slide-one span,
.img-slide-two span,
.img-slide-three span,
.img-slide-four span {
    position: absolute;
    right: 238px;
    bottom: 54px;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.hero .carousel-item img {
    height: 100%;
}

.hero .swiper {
    width: 100%;
    height: 100%;
}

.img-slide-one {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../Assets/Images/Meedad\ _\ Turnkey.jpg");
}

.img-slide-two {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../Assets/Images/AUS\ _\ Flooring.jpg");
}

.img-slide-three {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../Assets/Images/Starbucks\ _\ Cladding.jpg")
}

.img-slide-four {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("../../Assets/Images/Subeih\ Hospital\ _\ Turnkey.jpg")
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    justify-content: flex-end;
    padding-right: 51px !important;
    margin-bottom: 51px;

}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    opacity: 1 !important;
    width: 25px;
    height: 25.333px;
}

.swiper-pagination-bullet-active {
    background-color: #B31B1B !important;
}

@media all and (max-width:767px) {
    .home {
        margin-top: 114px !important;
    }

    .hero h2 {
        font-size: 25px;
        margin-right: 92px;
    }

    .hero h2+.flex-aswad-center svg {
        width: 114px;
        height: 37px;
        margin-top: -3px;

    }

    .hero h2+.flex-aswad-center {
        margin-left: 40px
    }

    .hero h4 {
        font-size: 25px;
        margin-left: 10px;
    }



    .img-slide-one {
        background-image: linear-gradient(to right, rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.7)), url("../../Assets/Images/Meedad-Turnkey-v\ copy.jpg");
    }

    .img-slide-two {
        background-image: linear-gradient(to right, rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.7)), url("../../Assets/Images/AUS-Flooring-v\ copy.jpg");
    }

    .img-slide-three {
        background-image: linear-gradient(to right, rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.7)), url("../../Assets/Images/Starbucks-Cladding-v\ copy.jpg");
    }

    .img-slide-four {
        background-image: linear-gradient(to right, rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.7)), url("../../Assets/Images/Subeih\ Hospital-Turnkey-v\ copy.jpg");
    }


    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        padding-right: 13px !important;
    }

    .img-slide-one span,
    .img-slide-two span,
    .img-slide-three span,
    .img-slide-four span {
        right: 124px;
        font-size: 20px;
        bottom: 56px;

    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        width: 15px;
        height: 15.333px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

    .hero h2,
    .hero h4 {
        font-size: 80px;
    }

    .img-slide-one,
    .img-slide-two,
    .img-slide-three,
    .img-slide-four {
        background-position: center;
    }


}