.Finishing .dots {
    width: 496px;
    height: 127px;
    right: -33px;
}

.department.Finishing:not(:first-of-type) {
    padding-top: 236px;
}

.last-department.Finishing {
    padding-bottom: 126px;
}

.Finishing .dots-2 {
    top: -10px;
    right: 20px;
}

.Finishing p {
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    /* 42px */
    text-transform: capitalize;
}

@media all and (max-width:767px) {
    .department {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 199px;
    }

    .department .text {
        padding-left: 0px;
    }

    .Finishing .dots {
        width: 207px;
        height: 70px;
        right: -68px;
        transform: rotate(90deg);
        top: 49px !important;
    }

    .images {
        margin-bottom: 46px;
    }

    .department h2 {
        font-size: 20px;
        margin-bottom: 5px;
    }

    .Finishing p {
        font-size: 14px;
    }

    .department.Finishing:not(:first-of-type) {
        padding-top: 111px;
    }

    .order-2 {
        order: 1 !important;
    }

    .order-1 {
        order: 2 !important;
    }

    .images-2 {
        padding-right: 0px !important;
    }
}

@media all and (width : 1024px) {
    .department {
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 319px;
    }

    .department .text {
        padding-left: 0px;
    }

    .Finishing .dots {
        width: 700px;
        height: 108px;
        right: -294px;
        transform: rotate(90deg);
        top: 222px !important;
    }

    .images {
        margin-bottom: 46px;
    }

    .department h2 {
        font-size: 35px;
        margin-bottom: 5px;
    }

    .Finishing p {
        font-size: 25px;
    }

    .department.Finishing:not(:first-of-type) {
        padding-top: 111px;
    }

    .order-2 {
        order: 1 !important;
    }

    .order-1 {
        order: 2 !important;
    }

    .images-2 {
        padding-right: 0px !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .department {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 199px;
    }

    .department .text {
        padding-left: 0px;
    }

    .Finishing .dots {
        width: 500px;
        height: 108px;
        right: -190px;
        transform: rotate(90deg);
        top: 155px !important;
    }

    .images {
        margin-bottom: 46px;
    }

    .department h2 {
        font-size: 35px;
        margin-bottom: 5px;
    }

    .Finishing p {
        font-size: 25px;
    }

    .department.Finishing:not(:first-of-type) {
        padding-top: 111px;
    }

    .order-2 {
        order: 1 !important;
    }

    .order-1 {
        order: 2 !important;
    }

    .images-2 {
        padding-right: 0px !important;
    }
}