.nav-trends {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    background-color: transparent;
    width: 100%;
    z-index: 11111;
}

.nav-trends .logo-nav {
    padding-top: 40px;
    padding-left: 60px;
}

.nav-trends .logo-nav svg,
.nav-trends .logo-nav img {
    width: 154.38px;
    height: 200.72px;
}

.nav-trends ul li a {
    color: #666;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.nav-trends ul .ar {
    font-family: 'Cairo', sans-serif;
}

.nav-trends ul .en {
    font-family: 'Roboto', sans-serif;
}


.nav-trends ul li a:hover,
.nav-trends ul.white li a:hover {
    transition: .3s;
    color: #B31B1B;
}

.nav-trends ul.white li a {
    color: #FFF;
}


.nav-trends ul li {
    display: inline;
    margin-right: 36px;
}

.burger {
    display: none;
}

.sm-con {
    display: none;
    transition: .4s;
}

.sm-nav {
    color: #666;
}

.translator {
    position: absolute;
    right: 50px;
    top: 20px;
    color: #B31B1B;
    font-size: 22px;
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
}

.translator:hover {
    color: #666;
    transition: .3s;
}

@media all and (max-width:767px) {
    .translator {
        display: none;
    }

    .nav-trends {
        position: fixed;
        background-color: white;
    }

    .nav-trends .logo-nav {
        padding-top: 13px;
        padding-left: 0px;
        padding-bottom: 13px;
    }

    .nav-trends .logo-nav img {
        width: 85px;
        height: 85px;
    }

    .nav-trends ul {
        display: none;
    }

    .nav-trends .sm-nav {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }

    .nav-trends ul li {
        margin-right: 0px;
    }

    .nav-trends .sm-nav li a,
    .nav-trends .sm-nav li {
        display: inline-block;
        margin-bottom: 20px;
        color: white;
        font-size: 17px;

    }

    .nav-trends .sm-nav li {
        font-family: 'Cairo', sans-serif;
    }

    .burger {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 7px;
    }

    .sm-con {
        padding-top: 54px;
        z-index: 111;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100vh;
        position: fixed;
        top: 117px;
        left: 0;
        width: 100%;
        background-color: #B31B1B;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .nav-trends {
        position: fixed;
        background-color: white;
    }

    .nav-trends .logo-nav {
        padding-top: 13px;
        padding-left: 0px;
        padding-bottom: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-trends .logo-nav img {
        width: 85px;
        height: 85px;
    }

    .nav-trends ul {
        display: none;
    }

    .nav-trends .sm-nav {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
    }

    .nav-trends .sm-nav li {
        margin-right: 0px;
        margin-bottom: 20px;
        color: white;
        font-size: 17px;

    }

    .burger {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 7px;
    }

    .sm-con {
        padding-top: 54px;
        z-index: 111;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100vh;
        position: fixed;
        top: 112px;
        left: 0;
        width: 100%;
        background-color: #B31B1B;
    }
}