.projects {
    padding-left: 226px;
    padding-bottom: 106px;
    padding-top: 336px;
}

.projects ul {
    margin-bottom: 100px;
    padding-left: 0px;
}

.projects ul li {
    display: inline;
    margin-right: 50px;
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
}

.projects ul .active {
    color: #B31B1B;
}

.projects .col {
    margin-bottom: 80px;
}

.projects img {
    width: 100%;
    height: 295px;
    margin-bottom: 13px;
}

.projects p {
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
}

.btn-modal-projects {
    display: flex;
    justify-content: flex-start;
    background: none;
    border: none;
    padding: 0;
}

.btn-modal-projects:hover,
.btn-modal-projects:focus,
.btn-modal-projects:active,
.btn-modal-projects:link {
    background: none !important;
    outline: none !important;
    border: none !important;
}

.modal {
    z-index: 111111111111111111;
}

.modal-fullscreen .modal-body {
    padding: 70px;
}

.modal-body {
    position: relative !important;
}

.modal-body .next,
.modal-body .prev {
    position: absolute;
    color: #B31B1B;
}

.modal-body .next {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


.modal-body .prev {
    cursor: pointer;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.modal {
    padding: 0 !important;
}


.img-modal-body img {
    height: 760px;
    margin-bottom: 54px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    color: #B31B1B;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
    border: none;
}

.projects ul li:hover {
    color: #B31B1B;
    transition: .3s;
}

.flex-aswad-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.img-modal-body .swiper-pagination-fraction,
.img-modal-body .swiper-pagination-custom,
.img-modal-body .swiper-horizontal>.swiper-pagination-bullets,
.img-modal-body .swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-bottom: 73px
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .projects {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 191px;
    }


    .projects .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
    }

    .projects ul {
        padding-left: 40px;
    }

}



@media all and (max-width:767px) {
    .img-modal-body img {
        width: 100% !important;
        height: 400px !important;
    }

    .modal-body .next {
        top: 43%;
        transform: translateY(-50%);
    }


    .modal-body .prev {
        cursor: pointer;
        left: 0;
        top: 44%;
        transform: translateY(-50%);
    }

    .modal-fullscreen .modal-body {
        padding: 30px;
        padding-top: 60px;

    }

    .projects {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 170px;
    }

    .projects .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
    }

    .projects p {
        font-size: 7.992px;
    }

    .projects img {
        width: 121.423px;
        height: 121.423px;
        margin-bottom: 13px;
    }

    .projects ul {
        margin-bottom: 16px;
    }


    .projects ul li {
        font-size: 8px;

    }
}

@media all and (width:1024px) {
    .modal-fullscreen .modal-body {
        padding-top: 220px !important;

    }
}

@media all and (min-width:390px) and (max-width:500px) {
    .modal-fullscreen .modal-body {
        padding: 30px !important;
        padding-top: 160px !important;

    }
}