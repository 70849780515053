.about-hero {
    padding-top: 336px;
    padding-bottom: 96px;
}

.about-hero .text {
    padding-left: 226px;
    font-family: 'Roboto', sans-serif;
}

.about-hero .text-ar,
.about-hero .h6-ar {
    font-family: 'Cairo', sans-serif;
    text-align: right;
}

.about-hero .h4-ar {
    font-family: 'Cairo', sans-serif;
    text-align: center;
}

.about-hero h2 {
    color: #B31B1B;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 49px;
}

.about-hero p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.about-hero p span {
    color: #B31B1B;
    font-weight: 500;
}

.about-hero .img {
    position: relative;
    width: 100%;
    height: 377px;
    margin-bottom: 24px;
}

.about-hero .col-xl-6.flex-aswad-center {
    padding-left: 125px;
    padding-right: 106px;
    flex-direction: column;
    margin-top: -28px;
}

.about-hero .img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.about-hero .img .img2 {
    top: 320px;
    z-index: -1;
    left: 332px;
}

.flex-aswad-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

/* .flex-aswad-col-ar {
    align-items: flex-end;
} */

.flex-aswad-col h6 {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 20px !important;

}

.flex-aswad-col span {
    color: #B31B1B;
    font-family: 'Roboto', sans-serif;
    font-size: 160px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 4.8px;
}

.m-negative {
    margin-top: -30px;
}

.flex-aswad-col:first-of-type {
    margin-right: 25px;
}

.flex-aswad-col:last-of-type {
    margin-top: 26px;
}

.flex-aswad-col h3 {
    color: #B31B1B;
    font-family: 'Roboto', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.flex-aswad-col h4 {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media all and (width:1024px) {
    .o1 {
        order: 2;
    }

    .o2 {
        order: 1;
    }

    .about-hero {
        padding-top: 326px;
    }

    .about-hero .img img {
        width: 80%;
    }

    .about-hero .text {
        padding-left: 48px;
        padding-right: 48px;
        margin-top: 50px;

    }

    .about-hero .img .img2 {
        top: 336px;
        left: 420px;
    }

    .about-hero .img+.flex-aswad-center {
        display: none;
    }

}

@media all and (max-width:767px) {
    .o1 {
        order: 2;
        margin-top: -30px;
    }

    .o2 {
        order: 1;
    }

    .about-hero .col-xl-6.flex-aswad-center {
        padding: 55px;
        padding-bottom: 0px;
    }

    .about-hero {
        padding-top: 35px;
    }

    .about-hero .text {
        padding-left: 0px;
    }

    .about-hero .img {
        margin-bottom: 0px;
    }

    .about-hero .img,
    .about-hero .text {
        margin-right: 20px;
        margin-left: 20px;
    }

    .about-hero .img img:nth-child(2n) {
        width: 90%;
        top: 74%;
        z-index: -1;
        left: 64%;
    }


    .about-hero .img+.flex-aswad-center {
        display: none;
    }

    .about-hero h2 {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .about-hero p {
        font-size: 12px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .o1 {
        order: 2;
        /* margin-top: -30px; */
    }

    .o2 {
        order: 1;
    }

    .about-hero {
        padding-top: 171px;
    }

    .about-hero .img img {
        width: 80%;
    }

    .about-hero .text {
        padding-left: 48px;
        padding-right: 48px;
        margin-top: 50px;
    }

    .about-hero .img .img2 {
        top: 324px;
        left: 348px;
    }

    .about-hero .img+.flex-aswad-center {
        display: none;
    }

}